.workWith {
    max-width: 1920px;
    margin: 0 auto;
    padding: 0 15px;
    margin-bottom: 80px;
}

.workWith-img {
    height: 120px;
}

.workWith-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.slick-slider {
    overflow: hidden;
}


@media (max-width: 991px) {
    .workWith {
        margin-bottom: 50px;
    }
    .workWith-img {
        height: 80px;
    }
}

@media (max-width: 480px) {
    .workWith {
        margin-bottom: 40px;
    }

    .workWith-img {
        height: 60px;
    }
}