.footer {
    background-color: #293583;
    padding: 80px 0;
}

.footer__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #ebf1f7;
}

.footer__logo,
.footer__contacts {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.footer__logo {
    align-items: flex-start;
}

    .footer__logo i {
        margin-bottom: 30px;
        font-size: 38px;
    }

        .footer__logo i:before {
            color: var(--color-white);
        }

    .footer__logo span {
        text-align: right;
        font-size: 20px;
        font-weight: 300;
        color: #EBF1F7;
    }

.footer__contacts a {
    color: #ebf1f7;
}

    .footer__contacts a.phone {
        font-size: 24px;
        font-weight: 700;
    }
    .phone1 {
        margin-bottom: 10px;
    }

    .footer__contacts a.mail {
        margin: 30px 0;
        font-size: 24px;
        font-weight: 500;
    }

.footer__contacts .footer__social {
    display: flex;
    justify-content: flex-end;
}

    .footer__contacts .footer__social a {
        margin-right: 16px;
        font-size: 32px;
    }

        .footer__contacts .footer__social a:last-child {
            margin-right: 0;
        }

@media (max-width: 767px) {
    .footer {
        padding: 80px 0;
    }

    .footer__contacts a.phone,
    .footer__contacts a.mail,
    .footer__logo span {
        font-size: 18px;
    }

    .footer__contacts a.mail {
        margin: 15px 0;
    }

    .footer__logo i {
        margin-bottom: 15px;
        font-size: 32px;
    }
}

@media (max-width: 532px) {
    .footer {
        padding: 40px 0;
    }

    .footer__contacts a.phone,
    .footer__contacts a.mail,
    .footer__logo span {
        font-size: 16px;
    }

    .footer__wrapper {
        flex-direction: column;
        align-items: unset;
    }

    .footer__contacts {
        margin-top: 20px;
    }

   
}
