.backToTop__container {
    position: fixed;
    bottom: 50px;
    right: 50px;
    height: 50px;
    width: 50px;
    font-size: 25px;
    background: var(--color-default);
    color: var(--color-white);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background 0.3s ease
}



@media (min-width: 1024px) {
    .backToTop__container:hover {
        background: var(--bg-btn-hover);
    }
}


@media (max-width: 767px) {
    .backToTop__container {
        width: 30px;
        height: 30px;
        bottom: 20px;
        right: 20px;
    }

        .backToTop__container svg {
            font-size: 15px;
        }
}
