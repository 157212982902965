.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 2px 20px 0px rgba(2, 102, 161, 0.2);
  backdrop-filter: blur(35px);
  z-index: 10000;
}

.header__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
}

.header__logo {
    cursor: pointer;
}

.icon__logo {
    cursor: pointer;
    position: relative;
    z-index: 101;
}

.icon-logo:before {
  font-size: 22px;
  color: var(--color-default);
}

.nav__list {
  display: flex;
  align-items: center;
}

.nav__list li a {
  position: relative;
}

.nav__list li a {
  padding: 26px 20px;
  font-size: 16px;
  font-weight: 600;
  color: var(--color-default);
}
.nav__list li button {
  width: 30px;
  height: 30px;
}

.menu-lang {
  order: 4;
  position: relative;
}

.menu-lang ul li {
  text-align: center;
  margin: 0 auto 10px auto;
  cursor: pointer;
}
.lang-menu__item.active {
    background-color: var(--color-default);
}
.lang-menu__item img {
  width: 25px;
  height: 25px;
}
.lang-button {
  background-repeat: no-repeat;
  background-size: cover;
}

@media (min-width: 1024px) {
  .icon-logo:before {
    font-size: 38px;
  }
  .nav__list li a:before {
    content: "";
    position: absolute;
    transition: transform 0.5s ease;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: var(--color-default);
    transform: scaleX(0);
  }

  .nav__list li a:hover:before {
    transform: scaleX(1);
  }

  .menu-lang.active:before {
    transform: scaleX(0);
  }
}

.menu-lang ul li:last-child {
  margin-bottom: 0;
}

.header__burger {
  width: 35px;
  height: 35px;
  position: relative;
  display: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 100;
}

.header__burger span {
  position: absolute;
  background-color: var(--color-default);
  width: 24px;
  height: 3px;
  transition: 0.3s;
  border-radius: 3px;
}

.header__burger:before,
.header__burger::after {
  content: "";
  position: absolute;
  background-color: var(--color-default);
  width: 24px;
  height: 3px;
  transition: 0.3s;
  border-radius: 3px;
}

.header__burger:before {
  top: 9px;
}

.header__burger::after {
  bottom: 9px;
}

.header__burger.active:before {
  transform: rotate(-45deg);
  top: 40%;
}

.header__burger.active::after {
  transform: rotate(45deg);
  bottom: 53%;
}

.header__burger.active span {
  width: 0;
}

@media (min-width: 767px) {
  .menu-lang.active .lang-button {
    margin-top: 32.9px;
    margin-bottom: 10px;
  }

  .menu-lang.active {
    margin-top: 70px;
    background-color: aliceblue;
    border-radius: 0 0 5px 5px;
  }
}

@media (max-width: 767px) {
  .header__logo {
    width: 96px;
    height: 22px;
  }




    .header__navigation {
        position: fixed;
        top: 0;
        left: -100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 100;
        height: 100vh;
        width: 100%;
        transition: left 0.5s ease;
        background-color: aliceblue;
    }

  .header__navigation.active {
    left: 0;
  }

  .nav__list {
    width: 100%;
    display: block;
    text-align: center;
  }

    .nav__list li {
        margin-bottom: 20px;
    }
    .nav__list li:last-child {
        margin-bottom: 0;
    }

  .header__burger {
    display: flex;
  }

  .nav__list li a {
    display: block;

  }
   
    .lang-menu__list {
        display: flex;
        justify-content: center;
        max-height: 0;
        transition: all 0.3s ease 0s;
    }


    .menu-lang ul li {
        margin: 0 10px;
        display: block;
        padding: 10px;
       
    }
    .lang-menu__item.active {
        border-radius: 50%;
        height: 45px;
    }
  .nav__list li button {
    margin-bottom: 15px;
  }
  .nav__list li a {
    padding: 10px;
  }
}
