.card strong {
  color: #88b04b;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}
.card p {
  color: #404f5e;
  font-size: 20px;
  margin: 0;
}
.card i {
  color: #9abc66;
  font-size: 100px;
  line-height: 200px;
  margin-left: -15px;
}
.card {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 60px;
  text-align: center;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
