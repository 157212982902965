.callback {
  margin-bottom: 97px;
}

.callback__title {
  margin-bottom: 10px;
}

.callback__subtitle {
  font-size: 23px;
  font-weight: 500;
  margin-bottom: 30px;
}

.callback__wrapper {
  display: flex;
  position: relative;
}

.callback__form {
  flex: 0 0 50%;
  display: flex;
  flex-direction: column;
}

.floating-label-input {
  position: relative;
  margin-bottom: 40px;
}

.label {
  position: absolute;
  left: 20px;
  top: 5px;
  transition: all 0.2s ease-in-out;
  transform-origin: top left;
  transform: translateY(20px);
  pointer-events: none;
  font-size: 14px;
  color: #999;
  background-color: var(--color-white);
}

.focused .label {
  transform: translateY(-10px);
  font-size: 14px;
  padding: 0 5px;
  border-radius: 50%;
  color: var(--color-default);
}

.form__input::placeholder {
  opacity: 0.5;
}

.form__input:focus {
  opacity: 1;
}

.form__input {
  padding: 20px;
  border-radius: 50px;
  border: 2px solid var(--color-default);
  color: var(--color-default);
  font-size: 20px;
  font-weight: 500;
  width: 100%;
}

textarea.form__input {
  resize: none;
  min-height: 150px;
  max-height: 350px;
  overflow: auto;
  border-radius: 20px;
}

.floating-label-input:last-of-type input {
  width: 100px;
  height: 40px;
  margin-right: 42px;
}

.callback__photo {
  position: absolute;
  right: -200px;
  z-index: -1;
}

.captcha {
  width: 150px;
  text-align: center;
  margin: 0 auto;
}

@media (max-width: 1440px) {
  .callback__photo {
    right: 500px;
    height: 507px;
    right: -147px;
    bottom: -107px;
  }

  .callback__photo img {
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 1200px) {
  .callback__form {
    flex: 0 0 70%;
  }

  .callback__photo {
    display: none;
  }
}

@media (max-width: 991px) {
  .callback__title {
    font-size: 24px;
  }

  .callback__subtitle {
    font-size: 18px;
  }

  .callback__form {
    flex: 0 0 100%;
  }

  .floating-label-input {
    margin-bottom: 20px;
  }

  .form__input {
    padding: 15px;
    font-size: 14px;
  }

  .label {
    transform: translateY(15px);
    top: 2px;
  }

  .focused .label {
    font-size: 12px;
  }

  .callback__form .btn {
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .callback {
    margin-bottom: 40px;
  }
}
@media (max-width: 532px) {
    .callback__form .btn {
        font-size: 14px;
        padding: 20px 5px;
    }
}

