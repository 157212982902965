.main {
  background-image: url("../../../public/img/bg.jpg");
  mix-blend-mode: hard-light;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: calc(100vh - 70px);
  margin-bottom: 100px;
}
    .main__wrapper {
        position: absolute;
        top: 50%;
        transform: translateY(-50%) !important;
        padding-right: 200px;
    }
.main__title {
  font-size: 46px;
  font-weight: 700;
}
.main__title b {
  font-weight: 700;
}
.main__text {
  font-size: 20px;
  line-height: 150%;
  font-weight: 400;
  margin: 30px 0;
  max-width: 1000px;
}
@media (max-width: 1440px) {
  .main {
    margin-bottom: 80px;
  }
  .main__wrapper {
    padding-right: 100px;
  }
}
@media (max-width: 1024px) {
  .main {
    margin-bottom: 60px;
  }
  .main__wrapper {
    padding-right: 50px;
  }
}
@media (max-width: 767px) {
  .main {
    margin-bottom: 40px;
  }
  .main__wrapper {
    padding-right: 16px;
  }
  .main__title {
    font-size: 32px;
    line-height: 130%;
  }
  .main__title b {
    font-weight: 900;
  }
  .main__text {
    font-size: 16px;
  }
  .main__wrapper .btn {
    padding: 20px;
    font-size: 16px;
  }
}

@media (max-width: 479px) {
  .main__text {
    font-size: 12px;
    margin: 10px 0;
  }
  .main__wrapper .btn {
    padding: 15px;
    font-size: 11px;
  }
}
