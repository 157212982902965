.services {
  margin-bottom: 100px;
}
.services__items {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -30px;
}
.services__columns {
  padding: 0 30px;
  flex: 0 0 50%;
  margin-bottom: 60px;
}
.services__columns:last-child,
.services__columns:nth-last-child(2) {
  margin-bottom: 0;
}
.services__columns .item__img {
  position: relative;
}
    .services__columns .item__img h3 {
        color: var(--color-white);
        font-size: calc(16px + 5 * (100vw / 1920));
        font-weight: 600;
        left: 30px;
        position: absolute;
        padding-right: 10px;
        bottom: 35px;
    }
.services__columns .item__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  border: 1px solid var(--color-default);
}
.skeleton-photo {
    position: relative;
}
.skeleton-text.absolute {
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: 350px;
}

@media (max-width: 1440px) {
  .services__columns {
    padding: 0 15px;
    margin-bottom: 50px;
  }
  .services__items {
    margin: 0 -15px;
  }
  .services__columns .item__img h3 {
    font-size: 24px;
    left: 25px;
  }
}

@media (max-width: 1024px) {
  .services {
    margin-bottom: 60px;
  }
  .services__columns .item__img h3 {
    font-size: 18px;
  }
}

@media (max-width: 991px) {
  .services__columns:last-child,
  .services__columns:nth-last-child(2) {
    margin-bottom: 25px;
  }
  .services__columns:last-child {
    margin-bottom: 0;
  }
  .services__columns {
    flex: 1 1 100%;
    margin-bottom: 25px;
  }
  .services__columns .item__img h3 {
    font-size: 24px;
    padding-right: 10px;
  }
}

@media (max-width: 767px) {
  .services__columns .item__img h3 {
    font-size: 14px;
    line-height: 120%;
    left: 8px;
    bottom: 15px;
  }
 
}

@media (max-width: 479px) {
  .services {
    margin-bottom: 40px;
  }
}
