.blurEffect {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4000px;
    background: radial-gradient(44.71% 44.71% at 50% 50%, rgba(70, 142, 201, 0.30) 0%, rgba(70, 142, 201, 0.00) 100%);
    z-index: -1;
    opacity: 0.2;
    overflow: hidden;
}
