.not-found {
    background-image: url("../../../public/img/not-found.jpg");
    mix-blend-mode: hard-light;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: calc(100vh - 70px);
}

.not-found__wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
}

    .not-found__wrapper p {
        font-size: 200px;
        margin-bottom: 50px;
        font-weight: 800;
    }

    .not-found__wrapper h1 {
        font-size: 40px;
        font-weight: 600;
        margin-bottom: 16px;
    }

    .not-found__wrapper h2 {
        color: rgba(41, 53, 131, 0.60);
        font-size: 24px;
        font-weight: 600;
    }


@media (max-width: 991px) {
    .not-found__wrapper p {
        font-size: 150px;
        margin-bottom: 30px;
    }

    .not-found__wrapper h1 {
        font-size: 35px;
    }

    .not-found__wrapper h2 {
        font-size: 22px;
    }
}


@media (max-width: 767px) {
    .not-found__wrapper p {
        font-size: 100px;
        margin-bottom: 20px;
    }

    .not-found__wrapper h1 {
        font-size: 25px;
    }

    .not-found__wrapper h2 {
        font-size: 16px;
    }
}
