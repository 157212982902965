.team__columns {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;
}

.team__items {
    flex: 0 0 25%;
    padding: 0 20px;
    margin-bottom: 50px;
}

.team__item {
    display: flex;
    flex-direction: column;
    height: 100%;
}

    .team__item .item__img {
        height: 296px;
        overflow: hidden;
        margin-bottom: 24px;
        border-radius: 10px;
    }

    .team__item img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
    }

    .team__item .item__name {
        color: var(--color-gray-900);
        font-size: 20px;
        font-weight: 700;
        line-height: 120%;
        margin-bottom: 6px;
    }

    .team__item .item__profession {
        color: var(--color-primary);
        font-size: 17px;
        font-weight: 400;
        line-height: 155.556%;
        margin-bottom: 15px;
    }

    .team__item .item__work {
        color: var(--color-gray-500);
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;
        margin-bottom: 24px;
        flex: 1 1 auto;
    }
    .team__item .item__social{
        display: flex;
        align-items:center
    }
        .team__item .item__social a {
            margin-right: 10px;
            transition: all 0.3s ease;
            color: #98A2B3;
            font-size: 24px;
        }
            .team__item .item__social a.facebook {
                font-size: 26px;
            }
            .team__item .facebook:hover {
                color: #3b5998;
                transition: fill 0.3s ease;
            }

    .team__item .linkedin:hover {
        color: #0077b5;
        transition: fill 0.3s ease;
    }

    .team__item .item__social a:last-child {
        margin-right: 0;
    }

@media (max-width: 1200px) {
    .team__items {
        flex: 0 0 33.333%;
    }
}

@media (max-width: 1024px) {
    .team__columns {
        margin: 0 -10px;
    }

    .team__items {
        padding: 0 10px;
    }
}

@media (max-width: 991px) {
    .team__items {
        flex: 0 0 50%;
        margin-bottom: 50px;
    }
}

@media (max-width: 767px) {
    .team__item .item__name {
        font-size: 18px;
    }

    .team__item .item__profession,
    .team__item .item__work {
        font-size: 16px;
    }
}

@media (max-width: 532px) {
    .team__items {
        flex: 0 0 100%;
        margin-bottom: 30px;
    }

    .team__item .item__img {
        height: 335px;
    }
}
