@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700;800;900&family=Noto+Sans+Armenian:wght@300;400;500;600;700;800;900&display=swap");

:root {
  --color-default: #004883;
  --color-white: #fff;
  --color-gray-900: #101828;
  --color-gray-500: #667085;
  --color-primary: #6941c6;
  --bg-btn-hover: #145c97;
  --bg-btn-pressed: #003e79;
  --font-family-default: "Inter", sans-serif;
  --font-family-armenian: "Noto Sans Armenian", sans-serif;
}

* {
  padding: 0;
  margin: 0;
  border: 0;
}

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:focus,
:active {
  outline: none;
}

a:focus,
a:active {
  outline: none;
}

nav,
footer,
header,
aside {
  display: block;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
#root {
    position:relative;
}
main {
  flex: 1 1 auto;
}
html,
body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  color: var(--color-default);
  font-family: var(--font-family-default);
  overflow-x: clip;
  line-height: 1;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
  scroll-padding-top: 80px;
}

@media (min-width: 1024.99px) {
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    cursor: pointer;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--color-default);
    border-radius: 3px;
  }
}

input,
button,
textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
  outline: none;
  border: none;
  background-color: transparent;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a,
a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

main {
  margin-top: 70px;
}

.container {
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 200px;
}

.btn {
    display: inline-block;
    color: var(--color-white);
    font-size: 20px;
    font-weight: 700;
    border-radius: 40px;
    background: var(--color-default);
    padding: 20px 50px;
    transition: background 0.3s ease;
}

.btn:hover {
  background: var(--bg-btn-hover);
}

.btn:active {
  background: var(--bg-btn-pressed);
}

.title {
  display: block;
  color: var(--color-default);
  font-size: 46px;
  font-weight: 700;
  margin-bottom: 50px;
}

.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100vh;
  z-index: 10000;
  background: #ccc9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.skeleton-text {
  margin-top: 10px;
}

@media (max-width: 1440px) {
  .container {
    padding: 0 100px;
  }

  .title {
    font-size: 32px;
  }
}

@media (max-width: 1024px) {
  .container {
    padding: 0 50px;
  }
}

@media (max-width: 767px) {
  .container {
    padding: 0 16px;
  }

  .title {
    margin-bottom: 30px;
    font-size: 25px;
  }
}
