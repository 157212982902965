.works {
    margin-bottom: 100px;
}

.works__items {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -30px;
}

.works__columns {
    padding: 0 30px;
    flex: 0 0 50%;
    margin-bottom: 120px;
}

.works__items.skl .works__columns {
    margin-bottom: 30px;
}
.works__columns .item__img {
    border-radius: 10px;
    overflow:hidden;
}
    .works__columns .item__img.your-site {
        border: 1px solid #00000033;
    }
    .works__columns .item__img img {
        width: 100%;
    }

.works__columns:last-child,
.works__columns:nth-last-child(2) {
    margin-bottom: 0;
}


.works__columns .item__info-top {
    margin-top: 20px;
}
.works__item {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.works__item h2 {
    color: var(--color-gray-900);
    font-size: 25px;
    font-weight: 700;
    line-height: 120%;
}

.works__item p {
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    color: var(--color-gray-500);
}

.works__item span {
    color: var(--color-primary);
    font-size: 18px;
    font-weight: 400;
    line-height: 155.556%;
}

.works__item .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.icon-blank {
    font-size: 24px;
    color: #98A2B3;
}

.item__img {
    position:relative;
}

    .item__img span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: var(--color-gray-900);
        font-size: 42px;
        font-weight: 800;
        line-height: 130%;
        opacity: 0.5;
    }

@media (max-width: 1440px) {
    .works__columns {
        padding: 0 15px;
        margin-bottom: 80px;
    }

    .works__items {
        margin: 0 -15px;
    }

    .works__item p {
        padding-right: 10px;
    }

    .item__img span {
        font-size: 32px;
    }
}

@media (max-width: 1024px) {
    .works {
        margin-bottom: 60px;
    }

    .works__item h2 {
        font-size: 20px;
        margin-right: 24px;
    }

    .works__columns .item__info-top {
        display: flex;
        align-items: center;
    }
}

@media (max-width: 991px) {
    .works__columns:nth-last-child(2) {
        margin-bottom: 25px;
    }

    .works__columns:last-child {
        margin-bottom: 0;
    }

    .works__columns {
        flex: 1 1 100%;
        margin-bottom: 25px;
    }
}

@media (max-width: 479px) {
    .works {
        margin-bottom: 40px;
    }

    .works__columns .item__info-top {
        margin-top: 16px;
        justify-content: space-between;
    }

    .icon-blank {
       font-size: 15px;
    }

    .works__columns .item__img
     {
        height: auto;
    }

    .works__item h2 {
        font-size: 16px;
        margin-right: 0;
    }

    .works__item span {
        font-size: 15px;
    }

    .works__item p {
        font-size: 12px;
    }

    .item__img span {
        font-size: 24px;
    }
}
